<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />
              <b-button
                variant="primary"
                :to="{ name: 'apps-inventoryorderdetailextend-edit', query: { inventory_detailid: this.inventory_detailid  }}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-inventoryorder-list'}"
                  style="margin-left: 2%;"
              >
                <span class="text-nowrap">返回</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-inventoryorderdetailextend-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          <div>
            <span v-if="data.item.type === 0">
              入库
            </span>
            <span v-else>
              出库
            </span>
          </div>

        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
           {{getCodeLabel('state', data.item.state)}}
        </template>

        <!-- Column: create_time -->
        <template #cell(create_time)="data">
          {{toTime(data.item.create_time)}}
        </template>

        <!-- Column: creator -->
        <template #cell(creator)="data">
          {{getCodeLabel("user",data.item.creator)}}
        </template>

        <!-- Column: update_time -->
        <template #cell(update_time)="data">
          {{toTime(data.item.update_time)}}
        </template>

        <!-- Column: updator -->
        <template #cell(updator)="data">
          {{getCodeLabel("user",data.item.updator)}}
        </template>

        <!-- Column: attachments -->
        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'inventoryOrderDetailExtend'"
                             :object_id="data.item.extend_id"
                             :readonly="true"
          />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!--单菜单-->
          <b-link >
            <b-button size="sm" @click="changeDetailState(data.item)"> 删除 </b-button>
          </b-link>

          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
<!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
<!--            <b-dropdown-item :to="{ name: 'apps-inventoryorderdetailextend-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>-->
            <b-dropdown-item :to="{ name: 'apps-inventoryorderdetailextend-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import inventoryorderdetailextendUseList from './inventoryorderdetailextendUseList'
import inventoryorderdetailextendStore from './inventoryorderdetailextendStore'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import axios from "@/libs/axios";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('inventoryorderdetailextend/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  created() {
    this.inventory_detailid = this.$route.query.inventory_detailid;
  },
  setup() {
    let toast = useToast()

    // Register module
    if (!store.hasModule('inventoryorderdetailextend')) store.registerModule('inventoryorderdetailextend', inventoryorderdetailextendStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('inventoryorderdetailextend')) store.unregisterModule('inventoryorderdetailextend')
    })

    const changeDetailState = function(item) {
      axios.get("/api/inventoryorderdetailextend/changeDetailExtendState", {params: item})
      .then(res => {
        if (res.data.code === 1) {
          toast.error(res.data.data + "，无法进行删除!")
        }else {
          toast.success("删除成功!")
          this.refetchData()
        }
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      inventory_detailid,
      // UI
    } = inventoryorderdetailextendUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      inventory_detailid,
      changeDetailState,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
